<template>
    <div class="search">
        <t_header  :searchText="searchText" :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header> 
        <div class="searchpage">
			<div class="searchpage_title">
				 <!-- v-if="courseList.length != 0" -->
				<p>搜索结果：</p>
				<!-- <p>更多<img src="@/assets/right_arrow.png"></p> -->
				<p>
					<span v-for="(item,index) in tagList" :key="index" :class="activeIndex==item.id?'active':''" @click="tabChage(item)">{{item.name}}</span>
				</p>
			</div>
			<div class="searchpage_tab">
				<p></p>
			</div>
			<!--课程数据-->
			<div class="searchpage_calss" v-if="activeIndex==1">
				<div @click="todetail(item,1)" v-for="(item,index) in courseList" :key="index" class="searchpage_calss_item">
					<div class="searchpage_calss_item_left">
						<img :src="item.coverPath">
					</div>
					<div class="searchpage_calss_item_right">
						<div class="searchpage_calss_item_right_top">
							<p class="searchpage_calss_item_right_top_title">{{item.courseName}}</p>
							<p class="searchpage_calss_item_right_top_name">{{item.courseSecondName}}</p>
						</div>
						<div class="searchpage_calss_item_right_bottom" v-if="item.isDiscount==0 && item.isFree==1">¥{{item.price}}</div>
						<div class="searchpage_calss_item_right_bottom" v-if="item.isDiscount==1 && item.isFree==1">¥{{item.discountPrice}}</div>
						<div class="searchpage_calss_item_right_bottom" v-if="item.isFree==0" style="color:#1656E4;">免费</div>
					</div>
				</div>
			</div>
			<!--问答数据-->
			<div class="searchpage_wd" v-if="activeIndex ==2">
				<div
					class="ask_main_left_list_item"
					v-for="(askItem,key) in wendaList"
					:key="key"
					@click="toAskDetail(askItem.wdId)"
				>
					<div class="ask_main_left_list_item_box">
						<div class="ask_main_left_list_item_box_left">
							<img :src="askItem.customerHeadPic" alt />
							<!-- <p>{{ askItem.questionName }}</p> -->
						</div>
						<div class="ask_main_left_list_item_box_right">
							<p>{{ askItem.questionName }}</p>
							<p
								v-if="askItem.answerUserId != '' && askItem.answerUserId != null && askItem.answerUserId != undefined"
							>
								<img :src="askItem.answerUserHeadPic" alt />
								<span>{{ askItem.answerUserNickName }}</span>
								<span>解答</span>
								<span>{{ askItem.answerTime }}</span>
								<span></span>
								<span>{{ askItem.readCount }}阅读</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<!--资料数据-->
			<div class="searchpage_calss" v-if="activeIndex ==3">
				<div class="datum_main_left_list_box_item" v-for="(item,index) in ziliaoList" :key="index" @click="ziliaoDetail(item)">
					<div class="datum_main_left_list_box_item_left">
						<img v-if="item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='pdf'" src="@/assets/pdf.png" alt="">
						<img v-else-if="item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='doc' || item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='docx'" src="@/assets/doc.png" alt="">
						<img v-else-if="item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='ppt'" src="@/assets/ppt.png" alt="">
						<img v-else-if="item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='xls' 
							|| item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='xlsx'
							|| item.filePath.substring(item.filePath.lastIndexOf('.')+1) =='csv'" src="@/assets/xls.png" alt="">
						<img v-else src="@/assets/weizhi.png" alt="">
						<div class="datum_main_left_list_box_item_left_content">
							<p>
								<span class="tuijian" v-if="item.isHot==1">推荐</span>
								<span class="filename">{{item.materialName}}</span>
							</p>
							<p>
								<span>{{item.materialSize}}KB</span>
								<span>下载 {{item.downloadCount}} 次</span>
							</p>
						</div>
					</div>
					<div class="datum_main_left_list_box_item_right">
						<el-button plain @click.stop="goHandouts(item)">下载</el-button>
					</div>
				</div>
			</div>
			<!--资讯数据-->
			<div class="searchpage_calss" v-if="activeIndex ==4">
				<div class="information_main_left_list_item" v-for="(item,index) in zixunList" :key="index" @click="zixunDetail(item)">
                        <p>
                            <span></span>
                            <span style="cursor:pointer;">{{item.informationTitle}}</span>
                        </p>
                        <p>{{item.intime}}</p>
                    </div>
			</div>


			<div class="block">
				<el-pagination layout="prev, pager, next" :total="count" @current-change="currentChange"></el-pagination>
			</div>
          	<!-- 空页面 -->
			<div class="article_empty" v-if="courseList.length == 0">
				<!-- <img src="@/assets/article_empty.png"> -->
				<p>暂无相关内容<br>换个关键词试试吧</p>
			</div>
        </div>
        <t_footer></t_footer> 
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { taocan_search,wenda_search,zixun_search,ziliao_search,add_download } from "@/api/other";

export default {
  name: 'searchpage',
  data(){
    return{
      	searchText:'',
      	courseList:[],
		page:0,
		count:0,
		activeIndex:1,
		num:0,
		tagList:[
			{
				id:1,
				name:'课程',
			},
			{
				id:2,
				name:'问答',
			},
			{
				id:3,
				name:'资料',
			},
			{
				id:4,
				name:'资讯',
			},
		],
		askList:[],
		wendaList:[],
		ziliaoList:[],
		zixunList:[],
		isUserInfo:false,
    }
  },
  components: {
    t_header,
    t_footer
  },
  watch:{
    $route(to,from){
        console.log(from.path);//从哪来
        console.log(to.path);//到哪去
        window.scrollTo(0,0);
        this.searchText = to.query.searchText
        this.getsearch()
    },
  },
  mounted(){
	console.log("searchPage mounted.....");
    this.searchText = this.$route.query.searchText
	console.log(this.$route.query.num)
	this.num = this.$route.query.num
	if(this.num==1){
		this.activeIndex = 1;
		this.taocan_search();
	}else if(this.num==2){
		this.activeIndex = 2;
		this.wenda_search()
	}else if(this.num==3){
		this.activeIndex = 3;
		this.ziliao_search();
	}
	
  },
  methods:{
	  // 父传子
	getTodos(e){
		console.log(e)
		this.isUserInfo=e
	},
	tabChage(item){
		console.log(item)
		this.activeIndex=item.id
		this.page=0
		if(this.activeIndex ==1){
			this.taocan_search();
		}else if(this.activeIndex ==2){
			this.wenda_search();
		}else if(this.activeIndex ==3){
			this.ziliao_search();
		}else if(this.activeIndex ==4){
			this.zixun_search();
		}
	},
	currentChange(page){
		this.page = page-1;
		if(this.activeIndex ==1){
			this.taocan_search();
		}else if(this.activeIndex ==2){
			this.wenda_search();
		}else if(this.activeIndex ==3){
			this.ziliao_search();
		}else if(this.activeIndex ==4){
			this.zixun_search();
		}
	},
    //结果
    taocan_search(){
		var obj={
			search:this.searchText,
			page:this.page
		}
		taocan_search(obj).then(res=>{
			console.log(res);
			if(res.data.code==0){
				this.count = res.data.count;
				this.courseList = res.data.data
			}else{
				this.$message.error(res.data.msg);
			}
		}).catch(error=>{
			console.log(error)
      	})
    },
	wenda_search(){
		var obj={
			search:this.searchText,
			page:this.page
		}
		wenda_search(obj).then(res=>{
			console.log(res);
			if(res.data.code==0){
				this.count = res.data.count;
				this.wendaList = res.data.data
			}else{
				this.$message.error(res.data.msg);
			}
		}).catch(error=>{
			console.log(error)
      	})
    },
	ziliao_search(){
		var obj={
			search:this.searchText,
			page:this.page
		}
		ziliao_search(obj).then(res=>{
			console.log(res);
			if(res.data.code==0){
				this.count = res.data.data.count;
				this.ziliaoList = res.data.data.materialList
			}else{
				this.$message.error(res.data.msg);
			}
		}).catch(error=>{
			console.log(error)
      	})
    },
	zixun_search(){
		var obj={
			search:this.searchText,
			page:this.page
		}
		zixun_search(obj).then(res=>{
			console.log(res);
			if(res.data.code==0){
				this.count = res.data.count;
				this.zixunList = res.data.data
			}else{
				this.$message.error(res.data.msg);
			}
		}).catch(error=>{
			console.log(error)
      	})
    },
    // 去详情
    todetail(item){
        let routeUrl =this.$router.resolve({
            path: '/coursedetail',
            query: {coursePackageId:item.coursePackageId,isFree:item.isFree}
        });
		window.open(routeUrl.href, '_blank');
    },
	toAskDetail(wdId){
		let routeUrl =this.$router.resolve({
            path: '/askDetail',
            query: {wdId:wdId}
        });
		window.open(routeUrl.href, '_blank');
	},
	ziliaoDetail(item){
		let routeUrl =this.$router.resolve({
			path: '/datumDetail', query: {
				item:JSON.stringify(item)
			}
		}); 
		window.open(routeUrl.href, '_blank');
	},
	zixunDetail(item){
		let routeUrl =this.$router.resolve({
			path: '/informationDetail',
			query: {
				informationId:item.informationId
			}
		})
		window.open(routeUrl.href, '_blank');
	},
	// 下载
	goHandouts(item){
		console.log(item)
		var obj={
			materialId:item.materialId
		}
		this.add_download(obj);
		console.log(item.filePath);
		// let routeData = this.$router.resolve({ path: item.filePath, query: {} });
		// window.open(routeData.href, '_blank');
		window.open(item.filePath, '_blank');
	},
	// 增加下载量
	add_download(obj){
		add_download(obj).then(res=>{
			console.log(res)
			console.log('下载了')
		}).catch(error=>{
			console.log(error)
		})
	},
    
  }
}
</script>
